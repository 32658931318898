import { apiCall } from "../api/api";

export const login = async (credentials: any): Promise<any> => {
  const response = await apiCall.post("user/signin", credentials);
  return response.data;
};













