import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import ListPageDX from "../components/Listpage";
import useUserService from "../shared/services/userservice";
import UserModal from "../components/editmodals/usermodal";

const Users = () => {
  const { getAllUsers } = useUserService();
  const [userId, setUserId] = useState<any>(null);
  const [actionType, setActionType] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const columns: GridColDef[] = [
    { field: "email", headerName: "Email", flex: 1 },
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      valueFormatter: (params) => {
        return params.value.charAt(0).toUpperCase() + params.value.slice(1);
      },
    },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
  ];

  const onEdit = (sponsorship: any) => {
    setUserId(sponsorship._id);
    setOpen(!open);
    setActionType("edit");
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setUserId(null);
    setOpen(!open);
    setActionType(null);

    if (refreshPage) setRefetch(!refetch);
  };

  return (
    <>
      {open && (
        <UserModal
          open={open}
          handleClose={onClose}
          userId={userId}
          actionType={actionType}
        />
      )}
      <ListPageDX
        listTitle="Users"
        columns={columns}
        getRowId={(row: any) => row._id}
        toCreate={toCreate}
        onEdit={onEdit}
        searchAndFilter={true}
        refetch={refetch}
        // onDelete={deleteUser}
        deleteMessage="User deleted successfully"
        fetchData={getAllUsers}
      />
    </>
  );
};

export default Users;
