import { useEffect } from "react";
import { useAuthContext } from "../../context/authcontext";
import { secureAPI } from "../api/api";

const useSecureAPI = () => {
  const { userData,signOut } = useAuthContext();

  useEffect(
    () => {
      const requestIntercept = secureAPI.interceptors.request.use(
        (config) => {
          if (!config.headers["user-id"]) {
            config.headers["user-id"] = userData._id;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );

      const responseIntercept = secureAPI.interceptors.response.use(
        (response) => response,
        async (error) => {
          console.log("secure api response error:", { error });

          if (error.response?.data instanceof Blob) {
            const message = await error.response?.data.text();
            const messageObj = JSON.parse(message);

            if (messageObj) return Promise.reject(messageObj);
            else return Promise.reject(message);
          } else {
            const errorMessage =
              error?.response?.data?.message ?? error.message ?? error;

            console.log({ errorMessage });
            if(errorMessage === "Token Expired!"){
              signOut();
            }
            return Promise.reject(errorMessage);
          }
        }
      );

      return () => {
        secureAPI.interceptors.request.eject(requestIntercept);
        secureAPI.interceptors.response.eject(responseIntercept);
      };
    },
    [
    ]
  );

  return secureAPI;
};

export default useSecureAPI;
