import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
// import CheckBoxDX from "../controls/checkboxdx";
import { useNotificationContext } from "../../context/notificationcontext";

import GridDX from "../Layout/CustomGrid";
import TextFieldDX from "../CustomTextfield";
import LoadingOverlay from "../loadingoverlay/LoadingOverlay";
import SelectListDX from "../controls/selectlistdx";
import DatePickerDX from "../controls/datepickerdx";
import { Checkbox, Typography } from "@mui/material";
import ButtonDX from "../Button";
import { AddCircleOutlined, RemoveCircleOutline } from "@mui/icons-material";
import useEventService from "../../shared/services/eventservices";

const EventModal = (props: any) => {
  const { open, handleClose, eventId, actionType } = props;
  const { setError, setInfo } = useNotificationContext();

  const { createEvent, getEventById, updateEvent } = useEventService();

  const slotDefautlValue = {
    slotName: "",
    price: "",
  };

  const [slots, setSlots] = useState([slotDefautlValue]);

  const defaultValues = {
    eventName: "",
    eventType: "",
    eventDate: new Date(),
    description: "",
    availableSlots: 1,
    slots: slots,
  };

  const [eventData, setEventData] = useState(defaultValues);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (eventId) {
      setIsLoading(true);
      getEventById(eventId)
        .then((res) => {
          setEventData(res.response);
          setSlots(res.response.slots);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (eventData.eventName.length === 0)
      newErrors["eventName"] = "Event name is required";
    if (eventData.eventType.length === 0)
      newErrors["eventType"] = "Event type is required";

    slots.map((slot, index) => {
      if (slot.slotName === "")
        newErrors[`slotName${index}`] = "Slot name is required";
      if (slot.price === "")
        newErrors[`price${index}`] = "Slot price is required";
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      const operation =
        eventId && actionType == "edit"
          ? updateEvent(eventId, { ...eventData, slots: slots })
          : createEvent({ ...eventData, slots: slots });
      operation
        .then(() => {
          eventId && actionType == "edit"
            ? setInfo("Event updated successfully")
            : setInfo("Event created successfully");
          handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setEventData({
      ...eventData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  const Eventtypes = [
    { text: "Muharram", value: "MUHARRAM" },
    { text: "Ramadhan", value: "RAMADAN" },
    { text: "General Programs", value: "GENERAL" },
  ];
  const Prices = [
    { text: "100", value: "100" },
    { text: "500", value: "500" },
    { text: "1000", value: "1000" },
  ];
  // 0 to 7 range dropdown
  const AvailableSlots = Array.from({ length: 100 }).map((v, i) => ({
    text: i + 1,
    value: i + 1,
  }));

  const handleSlotInputChange = (e: any, index: any) => {
    const { name, value, checked, type } = e.target;
    const updatedSlots = [...slots];
    updatedSlots[index] = {
      ...updatedSlots[index],
      [name]: type === "checkbox" ? checked : value,
    };
    setSlots(updatedSlots);
  };
  const [autoFilled, setAutoFilled] = useState(false);

  const autoFilledSlots = (checked: boolean) => {
    if (checked) {
      setAutoFilled(true);
      const updatedSlots = [...slots];
      updatedSlots.map((slot, index) => {
        updatedSlots[index] = {
          ...updatedSlots[index],
          slotName: `Program Family ${index + 1}`,
          price: "100",
        };
      });
      setSlots(updatedSlots);
    } else {
      setAutoFilled(false);
      setSlots((slots) => {
        return slots.map((slot) => {
          return {
            ...slot,
            slotName: "",
            price: "",
          };
        });
      });
    }
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={eventId && actionType == "edit" ? "Edit Event" : "Create Event"}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <LoadingOverlay styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={"Event Name"}
            name="eventName"
            value={eventData?.eventName}
            onChange={handleInputChange}
            error={errors["eventName"]}
          />
        </GridDX>
        <GridDX item xs={6}>
          <DatePickerDX
            format="ddd, MMM D, YYYY"
            label={"Event Date"}
            sx={{ flex: 1 }}
            name="eventDate"
            value={eventData?.eventDate}
            onChange={(value: any) => {
              handleDateChange(value, "eventDate");
            }}
          />
        </GridDX>
        <GridDX item xs={4}>
          <SelectListDX
            label={"Event Type"}
            items={Eventtypes}
            name={`eventType`}
            value={eventData.eventType}
            onChange={(e: any) => handleInputChange(e)}
            error={errors[`eventType`]}
            InputLabelProps={{
              shrink: eventData.eventType !== "", // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>

        <GridDX item xs={2}>
          <SelectListDX
            label={"Slots"}
            items={AvailableSlots}
            name={`availableSlots`}
            value={eventData.availableSlots}
            onChange={(e: any) => {
              handleInputChange(e);
              setSlots(
                Array.from({ length: e.target.value }).map((v, i) => ({
                  ...slotDefautlValue,
                }))
              );
            }}
            error={errors[`availableSlots`]}
          />
        </GridDX>

        <GridDX item xs={12}>
          <TextFieldDX
            label={"Event Description"}
            name="description"
            value={eventData.description}
            onChange={handleInputChange}
            error={errors["description"]}
          />
        </GridDX>

        <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 1 }}>
          Slots:
        </Typography>
        <Checkbox
          sx={{  mt: 1.5, mb: 0 }}
          checked={autoFilled}
          onChange={(e: any) => autoFilledSlots(e.target.checked)}
        />

        {slots.map((slot, index) => {
          return (
            <GridDX
              container
              columnSpacing={1}
              rowSpacing={2}
              sx={{ p: 1, pr: 0 }}
              key={index}
            >
              <GridDX item xs={slots.length > 1 ? 5 : 6}>
                <TextFieldDX
                  label={"Slot Name"}
                  name="slotName"
                  value={slot.slotName}
                  onChange={(e: any) => handleSlotInputChange(e, index)}
                  error={errors[`slotName${index}`]}
                />
              </GridDX>

              <GridDX item xs={slots.length > 1 ? 5 : 6}>
                <TextFieldDX
                  label={"Price"}
                  name="price"
                  value={slot.price}
                  onChange={(e: any) => handleSlotInputChange(e, index)}
                  error={errors[`price${index}`]}
                  type="number"
                />
              </GridDX>

              {/* delete slot button */}
              {slots.length !== 1 && (
                <GridDX
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RemoveCircleOutline
                    color="error"
                    fontSize="large"
                    cursor="pointer"
                    onClick={() => {
                      const updatedSlots = [...slots];
                      updatedSlots.splice(index, 1);
                      setSlots(updatedSlots);
                    }}
                  />
                </GridDX>
              )}
            </GridDX>
          );
        })}

        {/* <ButtonDX
          className=" ml-2"
          onClick={() => {
            setSlots([...slots, slotDefautlValue]);
          }}
        >
          Add Slot
        </ButtonDX> */}

        {/* <GridDX item xs={12}>
          <CheckBoxDX
            name="hidden"
            label={t(
              "Hide subject for correspondence under this privacy level in print views"
            )}
            // checked={eventData.hidden}
            // onChange={handleInputChange}
          // checked={isHidden}
          // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          //   setIsHidden(e.target.checked)
          // }
          />
        </GridDX>
        <GridDX item xs={12}>
          <CheckBoxDX
            name="classified"
            label={t("This is a classified level")}
            // checked={eventData.classified}
            // onChange={handleInputChange}
          />
        </GridDX> */}
      </GridDX>
    </AddEditModalDX>
  );
};

export default EventModal;
