import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../context/authcontext";
import { useNotificationContext } from "../context/notificationcontext";
import LoadingOverlay from "../components/loadingoverlay/LoadingOverlay";
import useUserService from "../shared/services/userservice";
import { RegExp } from "../global";

const ChangePass = () => {
  const { setError } = useNotificationContext();
  const { registerUser } = useAuthContext();
  const { changePassword } = useUserService();

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Required").matches(RegExp.password, {
      message:
        "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter , 1 number and 1 special character",
    }),
    newPassword: Yup.string().required("Required").matches(RegExp.password, {
      message:
        "Password must contain at least 8 characters,1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
    }),
    confirmPassword: Yup.string()
      //@ts-ignore
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
  });

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    console.log("handleSubmit", values);
    setIsLoading(true);
    setSubmitting(false);
    changePassword(values)
      .then((response) => {
        registerUser();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      {isLoading && <LoadingOverlay />}
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-b from-primary-dark to-primary-light">
        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-3xl font-extrabold text-primary-dark mb-6">
            Change Password
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-4">
                  <Field
                    id="oldPassword"
                    name="oldPassword"
                    type="password"
                    placeholder="Old Password"
                    className="w-full p-3 rounded-lg border border-gray-300"
                  />
                  <ErrorMessage
                    name="oldPassword"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="mb-4">
                  <Field
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    placeholder="New Password"
                    className="w-full p-3 rounded-lg border border-gray-300"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="mb-6">
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    className="w-full p-3 rounded-lg border border-gray-300"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div className="flex items-center justify-between">
                  <button
                    type="submit"
                    className=" bg-primary hover:bg-primary-dark text-white font-semibold py-3 px-6 rounded-lg focus:outline-none focus:ring focus:ring-primary-light"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangePass;
