import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../context/authcontext";
import { useNotificationContext } from "../context/notificationcontext";
import LoadingOverlay from "../components/loadingoverlay/LoadingOverlay";
import useUserService from "../shared/services/userservice";
import { RegExp } from "../global";

const Otp = () => {
  const { setInfo, setError } = useNotificationContext();
  const { verifyUser } = useAuthContext();
  const { verifyOtp, resendOtp } = useUserService();

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    verifyCode: "",
  };

  const validationSchema = Yup.object({
    // otp 6 digit number
    verifyCode: Yup.string().required("Required").matches(RegExp.otp, {
      message: "Otp must be 6 digit",
    }),
  });

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    setIsLoading(true);
    setSubmitting(false);
    verifyOtp(values)
      .then((response) => {
        setInfo("Otp verified successfully");
        verifyUser();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleResendOtp = () => {
    setIsLoading(true);
    resendOtp()
      .then((response) => {
        setInfo("Otp sent successfully");
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-b from-primary-dark to-primary-light">
        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-3xl font-extrabold text-primary-dark mb-6">
            Otp Verification
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-4">
                  <Field
                    id="verifyCode"
                    name="verifyCode"
                    placeholder="Otp"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-primary-light"
                  />
                  <ErrorMessage
                    name="verifyCode"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>

                <div className="flex items-center justify-between">
                  <button
                    type="submit"
                    className=" bg-primary hover:bg-primary-dark text-white font-semibold py-3 px-6 rounded-lg focus:outline-none focus:ring focus:ring-primary-light"
                    disabled={isSubmitting}
                  >
                    Verify
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {/* resend otp text */}
          <div className="mt-4 text-center">
            <button
              onClick={() => {
                handleResendOtp();
              }}
              className="text-primary hover:underline"
            >
              Resend Otp
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
