import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/authcontext";

const ProtectedRoute = ({ children }: any) => {
  const { isLoggedIn, isVerified, isRegistered } = useAuthContext();
  if (!isLoggedIn || !isVerified || !isRegistered) {
    return <Navigate to={"/"} replace />;
  }

  return children;
};

export default ProtectedRoute;
