import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/authcontext";
import { login } from "../shared/services/authservice";
import { useNotificationContext } from "../context/notificationcontext";
import LoadingOverlay from "../components/loadingoverlay/LoadingOverlay";
import { RegExp } from "../global";

const Login = () => {
  const { setError } = useNotificationContext();
  const { signIn, isLoggedIn, isVerified, isRegistered } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required").matches(RegExp.password, {
      message:
        "Password must contain at least 8 characters, 1 letter, 1 number and 1 special character",
    }),
  });

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    // Handle form submission logic here

    setIsLoading(true);
    setSubmitting(false);
    login(values)
      .then((response) => {
        signIn(response.response, response.token);
        console.log(response.response);
        if (!response.response.isRegistered) {
          navigate("/otp");
        } else {
          navigate("/events");
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-b from-primary-dark to-primary-light">
        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-3xl font-extrabold text-primary-dark mb-6">
            Login
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    Email
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-primary-light"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="password"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    Password
                  </label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-primary-light"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-xs mt-1"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <button
                    type="submit"
                    className=" bg-primary hover:bg-primary-dark text-white font-semibold py-3 px-6 rounded-lg focus:outline-none focus:ring focus:ring-primary-light"
                    disabled={isSubmitting}
                  >
                    Login
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Login;
