import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
import { useNotificationContext } from "../../context/notificationcontext";
import GridDX from "../Layout/CustomGrid";
import TextFieldDX from "../CustomTextfield";
import LoadingOverlay from "../loadingoverlay/LoadingOverlay";
import SelectListDX from "../controls/selectlistdx";
import useUserService from "../../shared/services/userservice";
import { RegExp } from "../../global";

const UserModal = (props: any) => {
  const { open, handleClose, userId, actionType } = props;
  const { setError, setInfo } = useNotificationContext();

  const { getUserById, updateUser, addUser } = useUserService();
  const defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    password: "",
  };

  const [data, setData] = useState(defaultValues);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      getUserById(userId)
        .then((res) => {
          console.log(res);
          setData(res.response);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.email) newErrors.email = "Email is required";
    if (!data.firstName) newErrors.firstName = "First Name is required";
    if (!data.lastName) newErrors.lastName = "Last Name is required";
    if (!data.gender) newErrors.gender = "Gender is required";
    if (!data.phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    if (!userId) {
      if (!data.password) newErrors.password = "Password is required";
      else if (RegExp.password.test(data.password) == false) {
        newErrors.password =
          "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter,  1 number and 1 special character";
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      const operation =
        userId && actionType == "edit"
          ? updateUser(userId, {
              firstName: data.firstName,
              lastName: data.lastName,
              gender: data.gender,
              phoneNumber: data.phoneNumber,
            })
          : addUser(data);
      operation
        .then(() => {
          userId && actionType == "edit"
            ? setInfo("User updated successfully")
            : setInfo("User added successfully");
          handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={userId ? "Edit User" : "Add User"}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <LoadingOverlay styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={!userId ? 6 : 12}>
          <TextFieldDX
            disabled={userId}
            label={"Email"}
            name="email"
            value={data.email}
            onChange={handleInputChange}
            error={errors["email"]}
          />
        </GridDX>
        {!userId && (
          <GridDX item xs={6}>
            <TextFieldDX
              label={"Password"}
              name="password"
              value={data.password}
              onChange={handleInputChange}
              error={errors["password"]}
            />
          </GridDX>
        )}
        <GridDX item xs={6}>
          <TextFieldDX
            label={"First Name"}
            name="firstName"
            value={data.firstName}
            onChange={handleInputChange}
            error={errors["firstName"]}
          />
        </GridDX>
        <GridDX item xs={6}>
          <TextFieldDX
            label={"Last Name"}
            name="lastName"
            value={data.lastName}
            onChange={handleInputChange}
            error={errors["lastName"]}
          />
        </GridDX>
        <GridDX item xs={6}>
          <TextFieldDX
            label={"Phone Number"}
            name="phoneNumber"
            value={data.phoneNumber}
            onChange={handleInputChange}
            error={errors["phoneNumber"]}
          />
        </GridDX>

        <GridDX item xs={6}>
          <SelectListDX
            label={"Gender"}
            name="gender"
            onChange={handleInputChange}
            value={data.gender}
            items={[
              { text: "Male", value: "male" },
              { text: "Female", value: "female" },
            ]}
            InputLabelProps={{ shrink: !!data.gender }}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default UserModal;
