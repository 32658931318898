import { useEffect, useState, createRef, useRef } from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";

import GridDX from "../Layout/CustomGrid";
import ButtonDX from "../Button";
import { useGridApiRef } from "@mui/x-data-grid";
import DeleteModal from "../modals/DeleteModal";

import {
  DataGrid,
} from "@mui/x-data-grid";
import { useNotificationContext } from "../../context/notificationcontext";
import { Delete, Edit, FileCopy } from "@mui/icons-material";

const ListPageDX = (props: any) => {
  const apiRef = useGridApiRef();
  const {
    toCreate,
    onEdit,
    onDelete,
    onCopy,
    deleteMessage,
    editLabel,
    createLabel,
    searchAndFilter,
    fetchData,
    refetch,
    checkboxSelection = false,
    getRowId,
  } = props;
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
  const [columns, setColumns] = useState<any>([]);

  const createBtn = toCreate || false;
  const searchBar = searchAndFilter != false;

  useEffect(() => {
    setColumns([
      ...props.columns,
      onEdit || onDelete
        ? {
            headerName: "Actions",
            field: "actions",
            type: "actions",
            // width: 50,
            getActions: buildActions,
          }
        : {},
    ]);
  }, []);
  // actions for table
  const buildActions = (params: GridRowParams) => {
    const actionsArray = [];

    if (onEdit)
      actionsArray.push(
        <GridActionsCellItem
          label={editLabel ? editLabel : "Edit"}
          showInMenu
          onClick={() => {
            onEdit(params.row);
          }}
          icon={<Edit />}
        />
      );

    if (onDelete)
      actionsArray.push(
        <GridActionsCellItem
          label="Delete"
          showInMenu
          onClick={() => {
            handleDeleteModalOpen(params.id);
          }}
          icon={<Delete />}
        />
      );

    if (onCopy)
      actionsArray.push(
        <GridActionsCellItem
          label={editLabel ? editLabel : "Copy"}
          showInMenu
          onClick={() => {
            onCopy(params.row);
          }}
          icon={<FileCopy />}
        />
      );
    return actionsArray;
  };

  const onRowClick = (params: GridRowParams) => onEdit && onEdit(params.row);

  const handleDeleteModalOpen = (id: any) => {
    setDeleteModal({ open: true, id });
  };

  const confirmDelete = () => {
    DeleteData(deleteModal.id);
    setDeleteModal({ open: false, id: null });
  };

  const { setInfo, setError } = useNotificationContext();
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // Initialize with the provided page or 0
    pageSize: 10, // Initialize with the provided pageSize or 10
  });

  const [isLoading, setIsLoading] = useState(false); // State to hold loading status
  const [rows, setRows] = useState([]); // State to hold data
  const [totalCount, setTotalCount] = useState(); // State to hold data
  const [filteredRows, setFilteredRows] = useState([]); // State to hold data

  const fetchDataFromApi = async () => {
    setIsLoading(true);
    fetchData(paginationModel.page, paginationModel.pageSize)
      .then((response: any) => {
        setTotalCount(response.totalCount);
        setRows(response.response);
        setFilteredRows(response.response);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setTimeout(() => {
      fetchDataFromApi();
    });
  }, [refetch, paginationModel.page, paginationModel.pageSize]);

  const DeleteData = async (id: any) => {
    setIsLoading(true);
    onDelete(id)
      .then(() => {
        setInfo(deleteMessage || "Row deleted successfully");
        fetchDataFromApi();
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const CustomNoRowsOverlay = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          textAlign: "center",
        }}
      >
        No rows to display
      </div>
    );
  };

  // const setGridFilterCriteria = (value: string) => {
  //   if (!value.trim()) {
  //     setFilteredRows(rows);
  //     return;
  //   }
  //   const lowercasedValue = value.toLowerCase();
  //   const newRows = rows.filter(({ eventName }: any) => eventName?.toLowerCase().includes(lowercasedValue)
  //   );
  //   setFilteredRows(newRows);
  // };

  return (
    <GridDX
      container
      sx={{ width: "100%", height: "100%", my: 1 }}
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
    >
      {deleteModal.open && (
        <DeleteModal
          open={deleteModal.open}
          onDeleteClick={confirmDelete}
          onCancelClick={() => setDeleteModal({ open: false, id: null })}
        />
      )}
      {createBtn && (
        <GridDX item xs={12} md={3}>
          <ButtonDX onClick={toCreate}> {createLabel || "Create"}</ButtonDX>
        </GridDX>
      )}

      {searchBar && (
        <GridDX item xs={12} md={createBtn ? 9 : 6}>
          {/* <GridSearchFilterTextbox
            setGridFilterCriteria={props.setGridFilterCriteria || setGridFilterCriteria}
          /> */}
        </GridDX>
      )}

      <GridDX item xs={12} sx={{ height: "100%" }}>
        <DataGrid
          // apiRef={apiRef}
          getRowId={getRowId}
          rows={filteredRows}
          columns={columns}
          rowCount={totalCount}
          onRowClick={onRowClick}
          disableRowSelectionOnClick={true}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          loading={isLoading}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          paginationModel={paginationModel}
          pageSizeOptions={[5, 10, 20, 25]}
          onPaginationModelChange={setPaginationModel}
          paginationMode="server"
          localeText={{
            MuiTablePagination: {
              labelRowsPerPage: "Rows per page:",
              labelDisplayedRows: ({ from, to, count }) => {
                return `${from}–${to} of ${
                  count !== -1 ? count : `more than ${to}`
                }`;
              },
            },
            columnMenuSortAsc: "Sort Z to A",
            columnMenuSortDesc: "Sort A to Z",
            footerTotalRows: "Total Rows",
            footerRowSelected: (count) =>
              count !== 1
                ? `${count.toLocaleString()} rows selected`
                : `${count.toLocaleString()} row selected`,
          }}
          getRowHeight={() => props.rowHeight ?? "auto"}
          checkboxSelection={checkboxSelection ?? true}
          sx={{
            ...props.sx,
            minHeight: 250,
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              minHeight: 250,
            },
          }}
        />
      </GridDX>
    </GridDX>
  );
};

export default ListPageDX;
