import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/login";
import Events from "./pages/events";
import DashboardLayout from "./components/Layout";
import ListPageLayout from "./components/Layout/ListpageLayout";
import { useEffect, useState } from "react";
import { useAuthContext } from "./context/authcontext";
import LoadingOverlay from "./components/loadingoverlay/LoadingOverlay";
import ProtectedRoute from "./components/route/ProtectedRoute";
import NotificationBarDX from "./components/NotificationBar";
import Sponsorships from "./pages/sponsorships";
import Users from "./pages/users";
import Otp from "./pages/otp";
import ChangePassword from "./pages/changepassword";
import UnProtectedRoute from "./components/route/UnProtectedRoute";

function App() {
  return (
    <div className="min-h-screen font-roboto">
      <NotificationBarDX />
      <ApplicationRoutes />
    </div>
  );
}

const ApplicationRoutes = () => {
  const { authInitialized } = useAuthContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authInitialized) setLoading(false);
  }, [authInitialized]);

  if (loading) return <LoadingOverlay />;

  return (
    <HashRouter>
      <Routes>
        <Route element={<UnProtectedRoute />}>
          <Route path="/" element={<Login />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/changepassword" element={<ChangePassword />} />
        </Route>

        <Route
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route element={<ListPageLayout />}>
            <Route path="/events" element={<Events />} />
            <Route path="/sponsorships" element={<Sponsorships />} />
            <Route path="/users" element={<Users />} />
          </Route>
        </Route>

        {/* Catch-all route for 404 errors */}
        <Route path="*" element={<Navigate to="/events" replace /> } />
      </Routes>
    </HashRouter>
  );
};

export default App;
