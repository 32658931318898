import useSecureAPI from "../hooks/usesecureapi";

const useUserService = () => {
    const secureAPI = useSecureAPI();

    const getAllUsers = async (page: any, pageSize: any) => {
        const response = await secureAPI.get(`user/allusers?page=${page + 1}&limit=${pageSize}`);
        return response.data;
    };


    const getUserById = async (id: any) => {
        const response = await secureAPI.get(`user/userbyid/${id}`);
        return response.data;
    };

    const updateUser = async (id: any, data: any) => {
        const response = await secureAPI.put(`user/updateuser/${id}`, data);
        return response.data;
    };

    const addUser = async (data: any) => {
        const response = await secureAPI.post(`user/createuser`, data);
        return response.data;
    }
    const addAdmin = async (data: any) => {
        const response = await secureAPI.post(`user/createadmin`, data);
        return response.data;
    }


    const verifyOtp = async (credentials: any): Promise<any> => {
        const response = await secureAPI.post("user/verifycode", credentials);
        return response.data;
    };
    //resendcode
    const resendOtp = async (): Promise<any> => {
        const response = await secureAPI.post("user/resendcode");
        return response.data;
    }

    const changePassword = async (credentials: any): Promise<any> => {
        const response = await secureAPI.put("user/changepassword", credentials);
        return response.data;
    }

    return {
        getAllUsers,
        getUserById,
        updateUser,
        addUser,
        verifyOtp,
        resendOtp,
        changePassword
    };
};

export default useUserService;