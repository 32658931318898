import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/authcontext";

import { Outlet } from 'react-router-dom';

const UnProtectedRoute = () => {
  const { isLoggedIn, isVerified, isRegistered } = useAuthContext();
  const location = useLocation();


  // If the user is NOT logged in and the current path is NOT "/", redirect to "/"
  if (!isLoggedIn && location.pathname !== "/") {
    return <Navigate to="/" replace />;
  }

  // If the user is logged in and fully verified, redirect to the dashboard (events)
  if (isLoggedIn && isRegistered && isVerified) {
    return <Navigate to="/events" replace />;
  }

  // If the user is logged in but not registered, redirect to OTP page
  if (isLoggedIn && !isVerified && location.pathname !== "/otp") {
    return <Navigate to="/otp" replace />;
  }

  // If the user is logged in, registered, but not verified, redirect to change password page
  if (
    isLoggedIn &&
    isVerified &&
    !isRegistered &&
    location.pathname !== "/changepassword"
  ) {
    return <Navigate to="/changepassword" replace />;
  }

  // Fallback: If none of the conditions apply, return children (unprotected routes like login)
  return <Outlet />;
};

export default UnProtectedRoute;
