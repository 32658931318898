import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";

import MuiDrawer from "@mui/material/Drawer";
import { primary } from "../../utils/colors";
import { useAuthContext } from "../../context/authcontext";
import { Person2Outlined } from "@mui/icons-material";

const MenuItems = (props: any) => {
  const { userData } = useAuthContext();
  const location = useLocation();

  const [open, setOpen] = useState(props ? props.open : false);
  const [activeIndex, setActiveIndex] = useState(0);
  const defaultItems = [
    {
      id: 1,
      text: "Events",
      icon: <InsertChartOutlinedIcon />,
      link: "/events",
    },
    {
      id: 2,
      text: "Sponsorships",
      icon: <ListAltOutlinedIcon />,
      link: "/sponsorships",
    },
  ];

  const adminMenuItems = [
    {
      id: 3,
      text: "Users",
      icon: <Person2Outlined />,
      link: "/users",
    },
  ];
  const [menuItems, setMenuItems] = useState(defaultItems);

  useEffect(() => {
    const pathName = location.pathname.substring(1);
    let currentItem: any = null;

    menuItems.forEach((i) => {
      if (i.link && i.link.includes(pathName)) {
        currentItem = i; //@ts-ignore
      } else if (i?.links && currentItem == null) {
        //@ts-ignore
        currentItem = i?.links.find((si) => si.link?.includes(pathName));
      }
    });

    if (currentItem) setActiveIndex(currentItem.id);

    if (userData.isAdmin) {
      setMenuItems([...defaultItems, ...adminMenuItems]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (props) setOpen(props?.open);
  }, [props?.open]);

  return (
    <List
      sx={{
        px: open ? 1 : 2,
        flex: "auto",
        overflowY: "auto",
        overflowX: open ? "auto" : "hidden",
      }}
    >
      {menuItems.map((item: any, index) => {
        if (item.link)
          return (
            <MenuItem
              key={`menuItem_${index}`}
              item={item}
              open={open}
              activeIndex={activeIndex}
            />
          );
        else
          return (
            <SubMenus
              key={`subMenu_${index}`}
              item={item}
              menuIsOpen={open}
              activeIndex={activeIndex}
            />
          );
      })}
    </List>
  );
};

const MenuItem = ({ item, open, activeIndex }: any) => {
  const activeBgColor = primary.DEFAULT;
  const activeTextColor = "white";
  const defaultBgColor = "transparent";
  const defaultTextColor = "#9197B3";
  const navigate = useNavigate();

  return (
    <ListItem
      key={item.text}
      disablePadding
      sx={{
        display: "block",
        borderRadius: 1,
        my: 2,
        background: item.id === activeIndex ? activeBgColor : defaultBgColor,
        color: item.id === activeIndex ? activeTextColor : defaultTextColor,
      }}
    >
      <ListItemButton
        sx={{
          height: 46,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          color: "inherit",
        }}
        onClick={() => {
          navigate(item.link);
          //          if (props.onClick) props.onClick();
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: "auto",
            ml: "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            opacity: open ? 1 : 0,
            textAlign: "initial",
            mr: "auto",
            ml: open ? "10px" : "auto",
          }}
        />
        {/* {item.id !== activeIndex && open && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              opacity: open ? 1 : 0,
              justifyContent: "flex-end",
              color: "inherit",
            }}
          >
            {item.icon}
          </ListItemIcon>
        )} */}
      </ListItemButton>
    </ListItem>
  );
};

const SubMenus = ({ item, menuIsOpen, activeIndex }: any) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  useEffect(() => {
    if (!menuIsOpen) setOpenSubMenu(false);
  }, [menuIsOpen]);

  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          color: "#9197B3",
          padding: "8px 20px",
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            //   mr: i18n.dir() === "ltr" ? (menuIsOpen ? 3 : "auto") : "auto",
            //   ml: i18n.dir() === "rtl" ? (menuIsOpen ? 3 : "auto") : "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            opacity: menuIsOpen ? 1 : 0,
            //   textAlign: i18n.dir() === "rtl" ? "right" : "left"
          }}
        />
        {menuIsOpen && (openSubMenu ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openSubMenu} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
        <List component="div" disablePadding>
          {item.links.map((item: any, index: number) => (
            <MenuItem
              key={`subMenu_menuIten_${index}`}
              item={item}
              index={index}
              open={openSubMenu}
              activeIndex={activeIndex}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export { MenuItems };

// to make it collapsible
// import { useLocation, useNavigate } from "react-router-dom";
const drawerWidth = 250; //306;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  position: "relative",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(11)} + 1px) !important`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  position: "relative",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: { border: 0 },
        }}
        sx={{
          display: { xs: "none", md: "block" },
        }}
        onMouseOver={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}
      >
        <MenuItems open={open} />
      </Drawer>
    </Box>
  );
}
